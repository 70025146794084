<template lang="pug">
v-menu(
  ref='menu'
  v-model='menu'
  :close-on-content-click='false'
  :return-value.sync='dates'
  :nudge-bottom='16'
  origin='center top'
  transition='scale-transition'
  offset-y
  min-width='290px')
  template(v-slot:activator='{on}')
    v-text-field(
      v-model='dateRange'
      :label='$lang.selectInterval'
      :prepend-icon='mdiCalendar'
      readonly
      v-on='on'
      :disabled='isError'
      :class='{"chart__picker-input": isError}')
  v-date-picker.reports__picker(
    v-model='dates'
    :first-day-of-week='firstDayOfWeek'
    range
    color='primary'
    :min='minCalendarAvailableDate'
    :max='maxCalendarAvailableDate')
    v-spacer
    v-btn(
      text
      color='primary'
      @click='menu = false') {{ $lang.cancel }}
    v-btn(
      text
      color='primary'
      :disabled='disabledBtnSetRange'
      @click='compareDates') {{ $lang.apply }}
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const { mapGetters: optionsMapGetters } = createNamespacedHelpers('options')
const { mapActions: chartsMapActions } = createNamespacedHelpers('charts')

import { YMD, getShortDateForCard } from '@/helpers/dateFormatter'

export default {
  name: 'ChartRangeDatePicker',
  props: {
    minCalendarAvailableDate: {
      type: String,
    },
    maxCalendarAvailableDate: {
      type: String,
      default: new Date(Date.now()).toISOString(),
    },
    isError: {
      type: Boolean,
      default: false,
    },
    initDates: {
      type: Array,
      default: () => [],
    },
    ttype: {
      type: String,
      default: 'cards_count',
    },
  },
  data: () => ({
    mdiCalendar,
    dates: [],
    range: {},
    menu: false,
  }),
  computed: {
    ...mapGetters(['lang']),
    ...optionsMapGetters(['firstDayOfWeek']),
    disabledBtnSetRange() {
      return !(this.dates.length === 2 && this.dates[0] !== this.dates[1])
    },
    dateRange() {
      const arr = []
      if (this.dates.length && this.dates[0]) {
        arr.push(getShortDateForCard(this.dates[0], this.lang))
      }
      if (this.dates.length && this.dates[1]) {
        arr.push(getShortDateForCard(this.dates[1], this.lang))
      }
      return arr.join(' ~ ')
    },
  },
  created() {
    this.dates = this.initDates
  },
  methods: {
    ...chartsMapActions(['getStatCards']),
    compareDates() {
      const d0 = new Date(this.dates[0])
      const d1 = new Date(this.dates[1])

      this.dates = d0 < d1 ? [YMD(d0), YMD(d1)] : [YMD(d1), YMD(d0)]

      this.range =
        d0 < d1
          ? { start: YMD(d0), end: YMD(d1) }
          : { start: YMD(d1), end: YMD(d0) }

      this.updateChartData()
    },
    async updateChartData() {
      this.$refs.menu.save(this.dates)
      await this.getStatCards({
        range: this.range,
        board_id: this.$route.params.id,
        ttype: this.ttype,
      })
      this.$emit('initChartData', this.dates)
    },
  },
}
</script>

<style lang="scss" scoped></style>
